
import { Options, Vue } from "vue-class-component";
import QuestionButton from "@/components/QuestionButton.vue";
import router from "@/router";
import * as wasm from "codeParser";
import { levelInfo } from "@/utils/Types";

import {
  createLevel,
  cloneLevel,
  deleteLevel,
  getMyLevelsTeacher,
} from "@/utils/apiCalls/LevelAPI";
@Options({
  data() {
    return {
      levels: [],
      wikiSubjects: [],
      errorVisible: "",
    };
  },
  mounted() {
    this.updateQuestionButton();
    this.updateLevelsList();
  },
  methods: {
    getImage(l: levelInfo): string {
      return "data:image/png; base64, " + l.preview;
    },

    updateLevelsList() {
      getMyLevelsTeacher().then((response) => {
        if (response !== null) this.levels = response;
      });
    },
    createNewLevel() {
      console.log('create new level!!!');
      this.$store.commit("setLevelMakerNewLevel", true);
      this.$store.state.levelMakerInfo.layout = null;
      this.$store.state.levelMakerInfo.name = "Placeholder";

      createLevel("robbie", "Placeholder").then((response) => {
        console.log('response', response);
        if (response != null) this.goToLevel(response);
      });
    },
    cloneMylevel(id: number) {
      cloneLevel(id).then(() => {
        this.updateLevelsList();
      });
    },
    deleteMyLevel(id: number) {
      console.log('deletelevel', id);
      deleteLevel(id).then(() => {
        this.updateLevelsList();
      });
      this.errorVisible = "";
    },
    goToLevel(level: levelInfo) {
      this.$store.commit("setLevelMaker", level);
      
      if (level.solution && level.solution !== '[]') {
        try {
          // Parse the level.solution JSON string
          let solution = JSON.parse(level.solution);

          // Dynamisch de dropdownVariables en worldVariableNames genereren zoals in loadCode
          let dropdownVariables: { [key: string]: string[] } = {};
          let worldVariableNames: string[] = [];

          // Haal werelddata op
          let worldData = this.$store.getters.getWorldData;

          // Voeg variabelen toe aan dropdownVariables en worldVariableNames
          worldData.variables.forEach((wVar: { type: string; name: string }) => {
            worldVariableNames.push(wVar.name);
            let options: string[] = [];
            worldData.constants.forEach((wCon: { type: string; name: string }) => {
              if (wCon.type === wVar.type) {
                options.push(wCon.name);
              }
            });
            dropdownVariables[wVar.name] = options;
          });

          // Convert solution to PSD using the same parameters as loadCode
          let psdAnswer = wasm.toPsd(
            JSON.stringify(solution),
            this.$store.getters.getIDELanguage,
            JSON.stringify(dropdownVariables),
            JSON.stringify(worldVariableNames)
          );

          console.log("psdAnswer", psdAnswer);
          this.$store.commit("setLevelMakerAnswerHTML", psdAnswer);

        } catch (error) {
          console.log("Could not parse level solution.");
          console.log(error);
        }
      }

      router.push("/docent/levelmaker");
    },
    updateQuestionButton() {
      this.wikiSubjects.push(
        {
          wikiButton: { name: this.$t("wiki.levMaker"), item: "levMakerPage" },
        }
        //here you can add more page references
      );
    },
  },
  components: {
    QuestionButton,
  },
})
export default class TeacherLevels extends Vue {}
